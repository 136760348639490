@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/*
https://bootstrap.build/app
*/

/* 
Show on large, not on small:
d-none d-lg-block 

Show on small, not on large:
d-block d-sm-none 

*/


html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-size: 1rem;

  scrollbar-width: none;
}


:root {
  --earsonly-orange: #ff6d2d;
  --earsonly-orange-light: #fdc8ac;
  --earsonly-orange-dark: #c0552c;
  --earsonly-gray: #5b6e7f;
  --earsonly-gray-dark: #36424d;
  --earsonly-white: #ffffff;
  --earsonly-background-secondary: #e8ecef;
}

.navbar-brand:hover {
  border-bottom: 1px solid transparent !important;

}

.nav-link {
  border-bottom: 1px solid transparent !important;
}

.nav-link:hover {
  border-bottom: 1px solid var(--earsonly-orange) !important;
}

body {
  font-family: Montserrat, sans-serif !important;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: var(--earsonly-gray);
    text-decoration: none;
    border-bottom: 1px solid transparent;

  }

  a:hover {
    color: var(--earsonly-orange);
    border-bottom: 1px solid var(--earsonly-orange);

  }
}

.button {
  background-color: var(--earsonly-orange) !important;
  border-color: var(--earsonly-orange) !important;
  text-align: center;
  font-weight: bold;
  margin-top: 1em;
}

h1 {
  padding-bottom: 50px;
  text-align: center;
}


.container-padding-top {
  padding-top: 5em;
}

.container-padding-bottom {
  padding-bottom: 20em;
}

.footer {
  background-color: var(--earsonly-background-secondary);
  color: var(--earsonly-gray);
  text-align: center;
  padding-top: 2rem;
  position: absolute;
  bottom: 0;
  width: 100vw;

  padding-left: .5em;
  padding-right: .5em;

  a {
    color: var(--earsonly-gray);
    text-decoration: none;
  }

  a:hover {
    color: var(--earsonly-orange);
  }

}

.footer-earsonly {
  font-size: 0.75em;
  padding-left: 2em;
  padding-bottom: 1em;
  text-align: left;

  @media only screen and (max-width: 992px) {
    padding-top: 1em;
  }

}


.footer-bold {
  font-weight: 600;
  color: var(--earsonly-gray-dark);
}

.hero {
  position: relative;
  padding-bottom: 120vh;
}


.hero-image {
  position: absolute;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  width: 150em;
  height: 150em;
  border-radius: 20px;
  place-items: center;

  animation: hero-scroll ease-out;
  animation-timeline: view();
  animation-range: exit 100px;
  z-index: -1;
}

@keyframes hero-scroll {
  to {
    overflow: hidden;
    border-radius: 90%;
    transform: scale(1.1);
    opacity: 0;
  }
}


.brandlogo {
  position: absolute;
  display: block;
  width: 70em;
  top: 5em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

}

.usp-card {
  width: auto;
  text-align: center;
  border: none !important;
}

.usp-image {
  height: 15rem;
  display: block;
  border-radius: 2rem;
}

.scroll-container {

  width: 3000px;
  overflow: hidden;
  margin: 50px auto;
  animation: scroll-images 30s linear infinite;
}

@keyframes scroll-images {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

.leaflet-container {
  height: 25em;
  width: 50em;
  border-radius: 25em 0em 0em 25em;
  border: 1px solid black;
}

.leaflet-marker-pane {
  width: 25px
}

.usecase {
  text-align: center;
}

.usecase-image {
  border-radius: 50px;

  width: 250px;
  height: auto;
}

.usecase-scroll-area {
  padding-top: 250px;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
  mask: linear-gradient(90deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);

}


.mission {
  padding-top: 250px;
}

.contact-form {

  @media only screen and (max-width: 768px) {
    padding-bottom: 50vh;
  }

  @media only screen and (min-width: 768px) {
    padding-top: 15vh;
  }
}

.text-area {
  resize: none;
}

.faq-menu-list {
  background-color: #5b6e7f20;
  border-radius: 0.375rem;
  margin-bottom: 3rem !important;
}


.faq-menu-header {
  padding-bottom: 1rem;
  font-weight: bold;
  color: var(--earsonly-orange)
}

.accordion-button:not(.collapsed) {
  background-color: var(--earsonly-background-secondary) !important;
  color: black !important;
}

.accordion-button:focus {
  border-color: var(--earsonly-orange) !important;
  box-shadow: var(--earsonly-orange) !important;
}

.faq-menu .nav-link {
  color: black;

}

.faq-menu .nav-link:hover {
  color: var(--earsonly-orange);
  border-bottom: 1px solid transparent !important;
  transition: none !important;

}

.faq-menu .nav-link.active {
  color: white !important;
  background-color: var(--earsonly-orange) !important;
}

.faq-menu .nav-link.disabled {
  background-color: var(--earsonly-white);
}

.faq-spacer {
  border-bottom: .25em solid white;
}

.faq-battery-image {

  img {
    margin-top: -2em;

    @media only screen and (max-width: 768px) {
      padding-right: 2em;
    }
  }
}

.faq-battery-image-title {
  position: relative;
  text-align: center;
  top: -2em;

  @media only screen and (max-width: 768px) {
    padding-right: 2em;
  }
}

.faq-link {
  color: var(--earsonly-orange)
}

.swiper {
  padding-right: 0 !important;
}

.testimonial {
  display: flex;
  align-items: center;
  position: relative
}

.testimonial-image {
  width: 35em;
  padding-right: 0 !important;

  @media only screen and (max-width: 992px) {
    width: 25em;
  }
}

.testimonial-image-col {
  text-align: end;
}

.testimonial-quote {
  color: var(--earsonly-orange);
  font-weight: bold;
  font-size: 1.75em;

  @media only screen and (max-width: 992px) {
    align-content: flex-end;
    min-height: 8em;
  }
}


.highlight {
  display: flex;
  align-items: center;
  padding-left: .5em;
  padding-right: .5em;
}

.highlight-row {
  padding-bottom: 5em;
  padding-top: 2em;
  align-items: baseline;
}

.image-highlight-text {
  padding-top: 3em;
  padding-bottom: 2em;
}

.image-highlight {
  @media only screen and (max-width: 768px) {
    width: 6em;
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    width: 8em;
    height: auto;
  }
}

.animation-row {
  position: relative;

  @media only screen and (min-width: 768px) {
    padding-top: 5em;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 30em;
  }
}

.gehoorbescherming {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.focus {
  opacity: 1;
  animation: gehoorbescherming_focus 10s linear infinite;
}

.dynamisch {
  opacity: 0;
  animation: gehoorbescherming_dynamisch 10s linear infinite;
}

.communicatie {
  opacity: 0;
  animation: gehoorbescherming_communicatie 10s linear infinite;
}

@keyframes gehoorbescherming_focus {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  34% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes gehoorbescherming_dynamisch {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  34% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  67% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes gehoorbescherming_communicatie {
  0% {
    opacity: 0
  }

  66% {
    opacity: 0;
  }

  67% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.appshowcase {
  padding-top: 2em;
  padding-left: .5em;
  background-color: var(--earsonly-background-secondary);
  padding-bottom: 2em;

  p {
    @media only screen and (min-width: 992px) {
      padding-top: 4em;
    }
  }

  b {
    color: var(--earsonly-orange)
  }

  img {
    width: 45em;

    @media only screen and (max-width: 992px) {
      width: 100%;
      display: block;
      margin: auto;
    }
  }
}

.app-information {
  p{
    padding-left: 2em;
  }
}



.navigation-brand {
  @media only screen and (min-width: 992px) {
    height: 60px;
    font-size: .8em;
  }

  img {
    height: 80px;
    margin-top: -10px;
    margin-bottom: -10px;

    @media only screen and (max-width: 992px) {
      margin-top: -30px;
      margin-bottom: -30px;
    }
  }
}

.nav-container {
  flex-direction: row-reverse !important;

}

.navigation-portal {
  @media only screen and (min-width: 992px) {
    position: relative;
    left: 50px;
  }
}

.page-not-found {
  text-align: center;

  h1 {
    text-align: center;
    padding: 0;
  }

  h2 {
    font-size: 3.5em;
  }

  a {
    background-color: var(--earsonly-orange);
    border-color: var(--earsonly-orange);
  }

  a:hover {
    background-color: var(--earsonly-orange-dark);
    border-color: var(--earsonly-orange-dark);
  }
}

.pointer {
  cursor: pointer
}

.app-image-instellingen {
  position: relative;
}

.geluidsinstellingen-a {
  position: absolute;
  top: 3em;
  left: 6.5em;
  transform: scale(1.5);
}

.geluidsinstellingen-b {
  position: absolute;
  top: 18em;
  left: 1.5em;
  transform: scale(1.5);
}

.geluidsinstellingen-microphone {
  position: absolute;
  top: 18em;
  left: 6em;
  filter: opacity(0);
  transform: scale(1);
}

.geluidsinstellingen-charging {
  position: absolute;
  top: 18em;
  left: 12em;
  filter: opacity(0);
  transform: scale(1);
}

.geluidsinstellingen-c {
  position: absolute;
  top: 18em;
  left: 18em;
  transform: scale(1.5);
}

.geluidsinstellingen-d {
  position: absolute;
  top: 21.5em;
  left: 4.5em;
  transform: scale(1.5);
}

.geluidsinstellingen-e {
  position: absolute;
  top: 24em;
  left: 9.5em;
  transform: scale(1.5);
}

.geluidsinstellingen-f {
  position: absolute;
  top: 29.5em;
  left: 9.5em;
  transform: scale(1.5);
}

.geluidsinstellingen-g {
  position: absolute;
  top: 35.5em;
  left: 9.5em;
  transform: scale(1.5);
}

.instellingen-a {
  position: absolute;
  top: 23.5em;
  left: 8em;
  transform: scale(1.5);
}

.instellingen-b {
  position: absolute;
  top: 33em;
  left: 8em;
  transform: scale(1.5);


}

.instellingen-c {
  position: absolute;
  top: 40em;
  left: 8em;
  transform: scale(1.5);
}


.right {
  text-align: right;
  float: right;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.blue {
  background-color: blue;
}

.yellow {
  background-color: yellow;
}