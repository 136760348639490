:root {
    --earsonly-orange: #ff6d2d;
    --earsonly-orange-light: #fdc8ac;
    --earsonly-orange-dark: #c0552c;
    --earsonly-gray: #5b6e7f;
    --earsonly-gray-dark: #36424d;
    --earsonly-white: #ffffff;
    --earsonly-footer: #f5f5f5;
    --earsonly-background-secondary: #e8ecef;
}

body {
    overflow-x: hidden;
}

h1 {
    color: var(--earsonly-orange) !important;
    font-weight: 900 !important;

    @media only screen and (max-width: 768px) {
        padding-top: 1em;
        font-size: 2em !important;
        text-align: center;
    }

    @media only screen and (min-width: 768px) {
        font-size: 3em !important;
        text-align: left;
    }

    @media only screen and (min-width: 992px) {
        font-size: 4em !important;
        text-align: left;
    }
}



.cradle-hero {
    position: inherit;

    h4 {
        @media only screen and (max-width: 768px) {
            padding-top: 8em;
            font-size: 1em !important;
            text-align: center;
        }

        @media only screen and (min-width: 768px) {
            font-size: 1.25em !important;
            text-align: center;
        }

        @media only screen and (min-width: 992px) {
            font-size: 1.5em !important;
            text-align: center;
        }
    }

    @media only screen and (max-width: 768px) {
        transform: translateY(1em);
        justify-content: center;
    }

    @media only screen and (min-width: 768px) {
        transform: translateY(-2.5em);
        align-content: center;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;

        @media only screen and (max-width: 768px) {
            transform: scale(0.7) translateY(-12em);
        }

        @media only screen and (min-width: 768px) {
            transform: scale(0.7) translateX(-10em);
        }

        @media only screen and (min-width: 992px) {
            transform: scale(1) translateY(-5em);
            height: 30em;
        }
    }

    h1 {
        @media only screen and (max-width: 768px) {
            transform: translateY(5em);
        }
    }
}

.product-animation-row {
    @media only screen and (min-width: 992px) {
        position: relative;
    }

    @media only screen and (max-width: 992px) {
        margin-left: -20px;
    }

}

.icon-text {
    font-size: 1.5em;
    align-self: center;
    font-weight: bold;
}

.icon-icon {
    width: 50px;
    height: 50px;

    @media only screen and (max-width: 768px) {
     margin-left: -1.5em;
        /* width: 40px; */
        /* height: 40px; */
    }
}


.highlight-icons {
    padding-top: 5em;
    justify-content: center;
    padding-left: 1.5em;
    padding-right: 0em;

    @media only screen and (min-width: 768px) {
        font-size: .75rem;
        padding-bottom: 3em;
    }

    @media only screen and (max-width: 768px) {
        font-size: 0.5rem;
        padding-bottom: 3em;
    }

    .col {
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .row {
        padding-top: 1em;
        padding-bottom: 1em;
        padding-right: 0;
    }

}

h5,
h3 {
    color: var(--earsonly-orange) !important;
    font-weight: bold !important;
}

.figure-img {
    background-color: #8181815d;

    @media only screen and (min-width: 768px) {
        transform: translateX(4em);
    }
}

.large-image-title {
    color: var(--earsonly-orange);
    font-weight: 900 !important;
    padding-top: 2em;


}

.certificering {
    @media only screen and (min-width: 992px) {
        transform: translateY(10em);
    }

    b {
        color: var(--earsonly-orange)
    }
}

.large-image {
    z-index: -1;

    @media only screen and (min-width: 992px) {
        margin-bottom: -5em;
        position: relative;
        top: -15em;
        left: 22em;
        width: 60em;
        height: auto;
    }

    @media only screen and (max-width: 992px) {
        width: 20em;
        height: auto;
        padding-bottom: 2em;
    }
}

.bone-br {
    @media only screen and (max-width: 1200px) {
        display: block;
    }

    @media only screen and (max-width: 992px) {
        display: inline;
    }

    @media only screen and (max-width: 768px) {
        display: block;
    }

}

.process {
    background-color: var(--earsonly-background-secondary);
    padding-top: 5em;
    padding-bottom: 2em;
    padding-left: 1em;
}

.proces-titel {
    position: relative;
    top: 1.45em;
    left: -.2em;
}

.swiper {
    padding-top: 4em !important;
    padding-bottom: 2em !important;
}

.swiper-pagination-bullet-active {
    background: var(--earsonly-orange) !important
}

.process-block {
    h2 {
        font-weight: bold;
        color: var(--earsonly-orange);
        text-align: left;
    }

    h5 {
        min-height: 
        2.5em;
        text-align: left;
    }

    img {
        
        padding-bottom: 2em;
        width: 45%;
    }
    p{
        text-align: left;
    }
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;
}

.product-hero {
    z-index: -1;
    margin: auto;
    display: block;

    @media only screen and (max-width: 992px) {
        padding-top: 3.5em;
        overflow: hidden;
    }
}

.prod-1 {
    opacity: 0;
    animation: product_1 10s linear infinite alternate;
}

.prod-2 {
    opacity: 0;
    animation: product_2 10s linear infinite alternate;
}

.prod-3 {
    opacity: 1;
    animation: product_3 10s linear infinite alternate;
}


@keyframes product_1 {
    0% {
        opacity: 1;
    }


    33% {
        opacity: 1;
    }

    34% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes product_2 {
    0% {
        opacity: 0;
    }


    32% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }


    66% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes product_3 {
    0% {
        opacity: 0;
    }

    55% {
        opacity: 0;
    }

    60% {
        opacity: .8;
    }

    66% {
        opacity: 1;
    }

}